import React from 'react'
import NavBar from '../../components/common/NavBar'
import CtaMobile from '../../components/common/CtaMobile'
import Divider from '../../components/common/Divider'
import Technologies from '../../components/common/Technologies'
import ServiceJumbotron from '../../components/services/ServiceJumbotron'
import ServiceMobileTags from '../../components/services/mobile/ServiceMobileTags'
import ServiceMobileOffer from '../../components/services/mobile/ServiceMobileOffer'
import ServiceMobileCta from '../../components/services/mobile/ServiceMobileCta'
import SingleTestimonial from '../../components/services/SingleTestimonial'
import ContactBottom from '../../components/contact/ContactBottom'
import MvpReasons from '../../components/services/MvpReasons'
import Faq from '../../components/common/Faq'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

const MobileAppDevelopmentPage = ({ data, location }) => {
  const { legalName } = data.info_mobile
  const testimonial = data.testimonials_mobile
  const {
    title,
    features,
    metaDescription,
    keywords,
    pageTitle,
  } = data.services_mobile
  const {
    email,
    phone,
    skype,
    phoneClean,
    skypeName,
    workTimeUTC,
  } = data.info_mobile.contact
  const { city, street, country, url } = data.info_mobile.address.office
  const description = data.services_mobile.description.split('%')
  const technologies = data.technologies_mobile.edges
  const faqs = data.faq_mobile.edges.map(e => ({
    question: e.node.question,
    answer: e.node.answer,
    id: e.node.id,
  }))
  const { servicesIlustrationMobile } = data

  return (
    <>
      <Helmet
        title={pageTitle}
        meta={[
          { name: 'description', content: metaDescription },
          { name: 'keywords', content: keywords },
        ]}
      />
      <NavBar location={location} />
      <ServiceJumbotron
        colorAccentClass={'service-mobile'}
        image={servicesIlustrationMobile.fluid.src}
        title={title}
        description={[
          description[0],
          <h2 key={'a'} className="seo-span">
            {description[1]}
          </h2>,
          description[2],
        ]}
        infoPackUrl={`${process.env.GATSBY_MAILING_URL}/api/infopack`}
        features={features}
      />
      <SingleTestimonial testimonial={testimonial} />
      <Divider />
      <ServiceMobileOffer />
      <ServiceMobileTags />
      <ServiceMobileCta />
      <Technologies
        listOfTechnologies={technologies}
        style={{ padding: '3.25rem 0 3.25rem 0' }}
      />
      <MvpReasons />
      <ContactBottom
        city={city}
        skype={skype}
        street={street}
        country={country}
        legalName={legalName}
        phoneClean={phoneClean}
        workTimeUTC={workTimeUTC}
        skypeName={skypeName}
        phone={phone}
        email={email}
        addressUrl={url}
      />
      <Divider fullWidth rainbow />
      <Faq listOfFaqs={faqs} />
      <CtaMobile lightTheme />
    </>
  )
}

export default MobileAppDevelopmentPage

export const query = graphql`
  query MobileDevPageQuery {
    info_mobile: dataJson {
      ...Contact
    }
    services_mobile: servicesYaml(
      title: { eq: "Développement des applications mobiles en Pologne" }
    ) {
      pageTitle
      metaDescription
      title
      keywords
      description
      features {
        icon
        title
        description
      }
    }
    technologies_mobile: allTechnologiesJson(
      filter: { displayIn: { eq: "services_mobile" } }
    ) {
      ...Technologies
    }
    faq_mobile: allFaqJson(filter: { displayIn: { eq: "services_mobile" } }) {
      ...Faqs
    }
    testimonials_mobile: testimonialsClientsJson(
      displayIn: { eq: "services_mobile" }
    ) {
      name
      company
      message
      avatar {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      role
      social {
        icon
        url
      }
    }
    servicesIlustrationMobile: imageSharp(
      fluid: { originalName: { regex: "/services_illustration_mobile/" } }
    ) {
      fluid(quality: 85) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
