import React from 'react'
 
const ServiceMobileTags = () => (
 <section className="service-mobile bg-faded py-5">
   <div className="container">
     <div className="row mb-5">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right mb-1">
           <span className="text-muted seo-regular">Développement d'applications mobiles</span>
           <br />
           <strong>tarifs</strong>
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <div className="row">
           <div className="col-sm-4">
             <strong>Devis Offshoring</strong>
             <br />
             qualité supérieure à prix attrayant
           </div>
           <div className="col-sm-4">
             <strong>Time & Materials</strong>
             <br />
             paiement en tranches
           </div>
           <div className="col-sm-4">
             <strong>GRATIS</strong>
             <br />
             évaluation gratuite de votre idée
           </div>
         </div>
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4">
         <h2 className="text-sm-center text-md-right">
           <span className="text-muted seo-regular">Développement</span>
           <br />
           d’applications mobiles
         </h2>
       </div>
       <div className="col-sm-12 col-md-8">
         <ul className="list-unstyled list-inline list-badges lead">
           <li>
             <span className="badge badge-light">Stratégie mobile</span>
           </li>
           <li>
             <span className="badge badge-light">Analyse commerciale</span>
           </li>
           <li>
             <span className="badge badge-light">
               Le meilleur projet d’application mobile
             </span>
           </li>
           <li>
             <strong className="badge badge-light">
               Prototypage d’applications
             </strong>
           </li>
           <li>
             <span className="badge badge-light">
               Intégration avec le nuage et API
             </span>
           </li>
           <li>
             <strong className="badge badge-light">
             Développement des applications mobiles en Pologne
             </strong>
           </li>
           <li>
             <span className="badge badge-light">Tests QA</span>
           </li>
           <li>
             <span className="badge badge-light">Publication dans l’App Store</span>
           </li>
           <li>
             <span className="badge badge-light">Soutien & Maintenance</span>
           </li>
         </ul>
         <div className="clearfix" />
       </div>
     </div>
 
     <div className="row">
       <div className="col-sm-12 col-md-4" />
       <div className="col-sm-12 col-md-8" />
     </div>
   </div>
 </section>
)
 
export default ServiceMobileTags
 
