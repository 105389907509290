import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import smoothScroll from 'scroll-to-element'
 
const ServiceMobileCta = () => (
 <section className="service-mobile cto">
   <div className="container">
     <div className="row py-5">
       <div className="col-xs-12 col-sm-4 col-lg-6">
         <div className="lead text-left text-sm-right">
           <h3 className="seo-span seo-strong text-muted">
             Applications mobiles sur mesure
           </h3>
           <br />
           <span className="text-service">
             pour iOS et Android
           </span>
           .<br />
           <a
             href="#contact-partial"
             data-form-category="mobileapps"
             className="btn btn-outline-extra1 my-2"
             id="custom-mobile-app-dev-request-btn"
             onClick={event => {
               event.preventDefault()
               smoothScroll('#contact-partial', { duration: 500 })
             }}
           >
             Demandez notre offre
           </a>
         </div>
       </div>
       <div className="col-xs-12 col-sm-4 col-lg-6">
         <ul className="fa-ul">
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
             Collaborez avec l’équipe de développeurs des{' '}
             <strong className="seo-regular">applications Android</strong> et experts en Kotlin
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
            Embauchez les <strong className="seo-regular">développeurs iOS</strong>{' '} spécialistes en Swift
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
             Développez une application pour Windows à partir de .NET
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
             Développez une application mobile, web et hybride
           </li>
           <li>
             <span className="fa-li">
               <FontAwesomeIcon icon={faAngleRight} />
             </span>
             Obtenez une UI/UX fonctionnel et jolie
           </li>
         </ul>
       </div>
     </div>
     <hr />
   </div>
 </section>
)
 
export default ServiceMobileCta