import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAndroid,
  faApple,
} from '@fortawesome/fontawesome-free-brands'
import Link from '../../common/Link'

const ServiceMobileOffer = () => (
  <section className="service-mobile">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Solutions en matière d’applications mobiles</span>
            <br />
            pour entreprises
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group text-center">
            <div className="card">
              <div className="card-body">
                <h3>Externalisation du développement mobile</h3>
                <p className="text-sm text-muted text-italic mt-2">
                   Pour en savoir plus sur le modèle idéal pour vous,{' '}
                  <Link to="/services/poland-software-outsourcing/">
                  vérifiez notre guide d’externalisation
                  </Link>
                  .
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>Transformez votre site internet en une application mobile</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Passez à l’étape suivante et transformez votre site Internet en une application rentable.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h3>Application mobilne sur mesure</h3>
                <p className="text-sm text-muted text-italic mt-2">
                Nous sommes capables de développer tout type d’application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right mb-2">
            <span className="text-muted seo-regular">Design des <br></br>applications mobiles </span>
            <br></br> <strong>solutions</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8 pt-2">
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faApple} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">Développement des applications iOS</strong>
              <p className="text-muted">
              Adaptées aux besoins d’iPhone, iPad et montres Apple, visant à attirer l’attention du client dans l’App Store, satisfaisant les utilisateurs les plus exigeants.
              </p>
            </div>
          </div>
          <div className="media feature">
            <div className="feature-icon mr-3">
              <FontAwesomeIcon icon={faAndroid} size="2x" className="fa-fw" />
            </div>
            <div className="media-body">
              <strong className="seo-h3">Développement des applications Android</strong>
              <p className="text-muted">
              Maintien d’une interface d’utilisateur cohérente et efficace dans l’écosystème Android vise à satisfaire aux besoins du plus grand groupe d’utilisateurs de smartphones.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceMobileOffer
